<template>
  <div class="ma-2"> 
    <v-card>
      <v-card-title>Customize Users Balance</v-card-title>
      <v-simple-table>
        <thead>
          <tr>
            <th width="250px">User</th>
            <th>
              <v-layout>
                <v-flex v-for="(type, index) in leaveTypesList" :key="index" style="max-width: 200px">
                  {{type.name}}
                </v-flex>
              </v-layout>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(staff, staffIndex) in staffItems" :key="staffIndex">
            <td>
              <v-avatar size="25">
                <img :src="`${imageRootUrl}profile/${staff.profile}`" alt="User profile" width="20px"> 
              </v-avatar>
              <span class="font-weight-bold mx-2 caption">
                {{staff.formdata.name}}
              </span>
            </td>
            <td>
              <v-layout>
                <v-flex v-for="(i, leaveTypeIndex) in staff.leaveBalanceList" :key="leaveTypeIndex" style="max-width: 200px">
                  <div class="d-flex justify-center align-center body-1" style="height: 100%" v-if="i.isNotAvailable"> -- </div>
                  <v-alert v-else class="mb-0 mx-1 my-1" text dense :color="i.leavetype.color">
                    <v-text-field v-if="i.isEditing" hide-details dense class="py-0 my-0" type="number"
                    v-model="i.balance" @focusout="updateBalance(staffIndex, leaveTypeIndex, i.balance)" clearable autofocus></v-text-field>
                    <p @click="i.isEditing = true; $forceUpdate()" class="mb-0 mt-1 caption font-weight-black" v-else> {{i.balance || 'No Limit'}} </p>
                  </v-alert>
                </v-flex>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      staffItems: [],
      leaveTypesList: []
    }
  },
  mounted () {
    this.$eventBus.$on('updateCustomBalanceTable', () => {
      this.getListHandler()
    })
    this.getListHandler()
  },
  methods: {
    getListHandler () {
      this.$store.dispatch('getUsersList', { only: [ 2 ] }).then((data) => {
        const staffItems = data
        this.$_execute('get', 'leave_type').then(({ data }) => {
          data = data.filter(x => x.isactive)
          this.leaveTypesList = data
          staffItems.forEach(staff => {
            staff.leaveBalanceList = []
            this.leaveTypesList.forEach(leavetype => {
              const leaveBalanceObj = staff.leavebalance.find(x => x.leavetypeId == leavetype._id)
              if (leaveBalanceObj) staff.leaveBalanceList.push({ leavetype, balance: leaveBalanceObj.balance, oldBalance: leaveBalanceObj.balance }) 
              else staff.leaveBalanceList.push({ leavetype, isNotAvailable: true })
            })
          })
          this.staffItems = staffItems
        })
      })
    },
    updateBalance (staffIndex, leaveTypeIndex, value) {
      let staffObj = this.staffItems[staffIndex]
      value = Number(value)
      const model = {
        user: staffObj._id, 
        leavetypeid: staffObj.leaveBalanceList[leaveTypeIndex].leavetype._id, 
        custombalance: staffObj.leaveBalanceList[leaveTypeIndex].oldBalance === null && !value ? null : value
      }
      this.$_execute(`put`, `leave/set-custom-balance`, model).then(() => {
        staffObj.balance = staffObj.oldBalance = value
        staffObj.leaveBalanceList[leaveTypeIndex].isEditing = false
        this.$set(this.staffItems, staffIndex, staffObj)
        this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success'})
      })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('updateCustomBalanceTable')
  }
}
</script>
